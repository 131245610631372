<template>
  <div>
    <FormContainer>
      <template #title>
        <span
          class="text-body-medium text-center text-white opacity-50 ltr:tracking-[0.72px] rtl:tracking-normal"
        >
          {{ $t("signinTitle") }}
        </span>
      </template>
      <template #subtitle>
        <h1
          class="text-display-small md:text-headline-big text-white opacity-[0.87] text-center ltr:tracking-[2.67px] rtl:tracking-normal"
        >
          {{ $t("Reset Password") }}
        </h1>
      </template>
      <form
        novalidate
        @submit.prevent="onSubmit"
        @invalid-submit.prevent="onInvalidSubmit"
        class="flex flex-col gap-y-6 justify-center"
      >
        <p class="text-body-medium text-white opacity-[0.87] tracking-[0.42px]">
          {{ $t("resetPassword") }}
        </p>

        <FormInput
          :label="t('Email')"
          :placeholder="t('Your email')"
          name="username"
        ></FormInput>

        <ButtonsCta
          :is-loading="isSubmitting"
          :disabled="!form.meta.value.valid"
          class="variant-primary"
        >
          {{ $t("Continue") }}
        </ButtonsCta>
      </form>
    </FormContainer>
  </div>
</template>

<script setup>
import { useForm } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import { useGetStartedStore } from "@/stores/getStarted";

const { onListen } = useKeyBoardEvent("enter");
const { openToast } = useToast();
const getStartedStore = useGetStartedStore();

const props = defineProps({
  standalone: Boolean,
});

useFormRules();

const isSubmitting = ref(false);

const { t } = useI18n();

const schema = {
  username: "required|email",
};

const form = useForm({
  validationSchema: schema,
  initialValues: {
    username: getStartedStore.createForm.username,
  },
});

localize({
  en: {
    fields: {
      username: {
        required: "Please enter a valid email address",
        email: "Please enter a valid email address",
      },
    },
  },
});

const onInvalidSubmit = () => {};

const onSubmit = async (event) => {
  event?.preventDefault();
  isSubmitting.value = true;

  const { values } = form;

  const payload = {
    body: { ...values },
  };

  const { status, msg, code } = await usePostMethod(
    "/api/uc/manage/password/reset/otp",
    payload
  );

  if (status == 200 && code == "SUCCESS") {
    openToast({ content: t("resetPassEmailSent") });
    getStartedStore.step = "resetPasswordValidateCode";
    getStartedStore.createForm.username = values.username;
    // proceed
  } else {
    openToast({ content: msg, type: "error" });
  }
  isSubmitting.value = false;
};
onListen(() => form.meta.value.valid && onSubmit());
</script>

<script>
export default { name: "SignIn" };
</script>

<style lang="scss" scoped></style>
